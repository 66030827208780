import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import CustomerResult from '@/views/CustomerResult.vue';
import LeadView from '@/views/LeadView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/:codeLink', // rota dinâmica
    name: 'Compartilhador',
    component: Home,
  },
  {
    path: '/lead/:clientId', // rota dinâmica
    name: 'Cliente',
    component: LeadView,
  },
  {
    path: '/obrigado', 
    name: 'Obrigado',
    component: CustomerResult, //rota para marketing trackear - force
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
